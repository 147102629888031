import { lazy } from 'react';

export const routeConfig = [
  {
    id: 'TopPage',
    path: '/',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/login/LoginPage'),
    ),
    isProtected: false,
  },
  // {
  //   id: "RegisterPage",
  //   path: "/register",
  //   component: lazy(
  //     () => import(/* webpackPrefetch: true */ "@pages/register/RegisterPage"),
  //   ),
  //   isProtected: false,
  // },
  {
    id: 'LoginPage',
    path: '/login',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/login/LoginPage'),
    ),
    isProtected: false,
  },
  // {
  //   id: "InGameLace",
  //   name: "InGame Lace",
  //   path: "/game/ingame/lace",
  //   component: lazy(
  //     () =>
  //       import(
  //         /* webpackPrefetch: true */ "@pages/game-panel/game/ingame/Lace"
  //       ),
  //   ),
  //   isProtected: false,
  // },
  // {
  //   id: "InGameTraining",
  //   name: "InGame Training",
  //   path: "/game/ingame/training",
  //   component: lazy(
  //     () =>
  //       import(
  //         /* webpackPrefetch: true */ "@pages/game-panel/game/ingame/Training"
  //       ),
  //   ),
  //   isProtected: false,
  // },
  // {
  //   id: "OutgameGeneral",
  //   name: "Outgame General",
  //   path: "/game/outgame/general",
  //   component: lazy(
  //     () =>
  //       import(
  //         /* webpackPrefetch: true */ "@pages/game-panel/game/outgame/General"
  //       ),
  //   ),
  //   isProtected: false,
  // },
  {
    id: 'Environment',
    name: 'Environment',
    path: '/environment',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/environment'),
    ),
    isProtected: true,
  },
  {
    id: 'ItemEditor',
    name: 'ItemEditor',
    path: '/item',
    component: lazy(() => import(/* webpackPrefetch: true */ '@pages/item')),
    isProtected: true,
  },
  {
    id: 'TokenSetting',
    name: 'Token Setting',
    path: '/token-setting',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/token-setting'),
    ),
    isProtected: true,
  },
  {
    id: 'Maintenance',
    name: 'Maintenance',
    path: '/maintenance',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/maintenance'),
    ),
    isProtected: true,
  },
  {
    id: 'KPI',
    name: 'KPI',
    path: '/kpi',
    component: lazy(() => import(/* webpackPrefetch: true */ '@pages/kpi')),
    isProtected: true,
  },
  {
    id: 'install',
    name: 'Install',
    path: '/kpi/install',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/kpi/selection/install'),
    ),
    isProtected: true,
  },
  {
    id: 'AU',
    name: 'AU',
    path: '/kpi/AU',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/kpi/selection/AU'),
    ),
    isProtected: true,
  },
  {
    id: 'PU',
    name: 'PU',
    path: '/kpi/PU',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/kpi/selection/PU'),
    ),
    isProtected: true,
  },
  {
    id: 'ARPU',
    name: 'ARPU',
    path: '/kpi/ARPU',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/kpi/selection/ARPU'),
    ),
    isProtected: true,
  },
  {
    id: 'ARPPU',
    name: 'ARPU',
    path: '/kpi/ARPPU',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/kpi/selection/ARPPU'),
    ),
    isProtected: true,
  },
  {
    id: 'FqContinuityRate',
    name: 'FqContinuityRate',
    path: '/kpi/FqContinuityRate',
    component: lazy(
      () =>
        import(
          /* webpackPrefetch: true */ '@pages/kpi/selection/FQContinuityRate'
        ),
    ),
    isProtected: true,
  },
  {
    id: 'FqTotalSales',
    name: 'FqTotalSales',
    path: '/kpi/FqTotalSales',
    component: lazy(
      () =>
        import(/* webpackPrefetch: true */ '@pages/kpi/selection/FQTotalSales'),
    ),
    isProtected: true,
  },
  {
    id: 'SalesNcoin',
    name: 'SalesNcoin',
    path: '/kpi/sales/Ncoin',
    component: lazy(
      () =>
        import(/* webpackPrefetch: true */ '@pages/kpi/selection/sales/Ncoin'),
    ),
    isProtected: true,
  },
  {
    id: 'SalesGacha',
    name: 'SalesGacha',
    path: '/kpi/sales/GACHA',
    component: lazy(
      () =>
        import(/* webpackPrefetch: true */ '@pages/kpi/selection/sales/gacha'),
    ),
    isProtected: true,
  },
  {
    id: 'SalesNBGDcoin',
    name: 'SalesNBGDcoin',
    path: '/kpi/sales/NBGDcoin',
    component: lazy(
      () =>
        import(
          /* webpackPrefetch: true */ '@pages/kpi/selection/sales/NBGDcoin'
        ),
    ),
    isProtected: true,
  },
  {
    id: 'UserTrendsCoin',
    name: 'UserTrendsCoin',
    path: '/kpi/userTrends/coin',
    component: lazy(
      () =>
        import(
          /* webpackPrefetch: true */ '@pages/kpi/selection/userTrends/coin'
        ),
    ),
    isProtected: true,
  },
  {
    id: 'UserTrendsItem',
    name: 'UserTrendsItem',
    path: '/kpi/userTrends/item',
    component: lazy(
      () =>
        import(
          /* webpackPrefetch: true */ '@pages/kpi/selection/userTrends/item'
        ),
    ),
    isProtected: true,
  },
  {
    id: 'UserTrendsNBGDconsumption',
    name: 'UserTrendsNBGDconsumption',
    path: '/kpi/userTrends/NBGDconsumption',
    component: lazy(
      () =>
        import(
          /* webpackPrefetch: true */ '@pages/kpi/selection/userTrends/NBGDconsumption'
        ),
    ),
    isProtected: true,
  },
  {
    id: 'UserTrendsTrainingComplete',
    name: 'UserTrendsTrainingComplete',
    path: '/kpi/userTrends/TrainingComplete',
    component: lazy(
      () =>
        import(
          /* webpackPrefetch: true */ '@pages/kpi/selection/userTrends/TrainingComplete'
        ),
    ),
    isProtected: true,
  },
  {
    id: 'UserTrendsSearch',
    name: 'UserTrendsSearch',
    path: '/kpi/userTrends/search',
    component: lazy(
      () =>
        import(
          /* webpackPrefetch: true */ '@pages/kpi/selection/userTrends/search'
        ),
    ),
    isProtected: true,
  },
  {
    id: 'EndUserManagement',
    name: 'End User Management',
    path: '/management-end-user',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/management-end-user'),
    ),
    isProtected: true,
  },
  {
    id: 'ManagementAdmin',
    name: 'Management Admin',
    path: '/management-admin',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/management-admin'),
    ),
    isProtected: true,
  },
  {
    id: 'Notice',
    name: 'Notice',
    path: '/notice',
    component: lazy(() => import(/* webpackPrefetch: true */ '@pages/notice')),
    isProtected: true,
  },
  {
    id: 'Present',
    name: 'Present',
    path: '/present',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/present/present'),
    ),
    isProtected: true,
  },
  {
    id: 'ChangeTime',
    name: 'Change Time',
    path: '/change-time',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/change-time'),
    ),
    isProtected: true,
  },
];
