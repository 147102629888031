import { Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '@apps/hooks';
import AppLayout from './layout/AppLayout';
import { roleKeys } from 'enums/globalEnums';
import { readRoles } from '@utils/localStorage';

function PrivateRoute({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const { access_token } = useAppSelector(state => state.auth);
  if (!access_token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  let userRoles: any = readRoles();
  userRoles = JSON.parse(userRoles);
  const pathName = window.location.pathname;
  const getPathOfRole = () => {
    const paths: any = {};
    paths[roleKeys.OPERATOR] = '/token-setting,/change-time';
    paths[roleKeys.CUSTOMER_SUPPORTER] = '/change-time,/management-end-user';

    return paths;
  };

  const env = localStorage.getItem('env');
  if (!userRoles.includes(roleKeys.SUPER_USER) && pathName !== '/environment') {
    const paths = getPathOfRole();
    const valids: any = [];
    if (env === process.env.REACT_APP_RELEASE_NAME) {
      delete paths[roleKeys.OPERATOR];
    }
    const validsPath = paths[userRoles].split(',');
    validsPath.forEach((path: any) => valids.push(path));

    if (!valids.includes(pathName)) {
      return <Navigate to="/environment" state={{ from: location }} replace />;
    }
  }

  return (
    <AppLayout showMenu={true}>
      <Suspense fallback={null}>{children}</Suspense>
    </AppLayout>
  );
}

export default PrivateRoute;
