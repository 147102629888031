import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '@apps/config/baseQueryWithReauth';
import { readAccessToken } from '@utils/localStorage';
import { apiItem } from '../../enums/globalEnums';

export const itemApi = createApi({
  reducerPath: 'itemApi',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    createItem: build.mutation({
      query: payload => ({
        url: apiItem.CREATE,
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
    fetchItem: build.query({
      query: params => ({
        url: apiItem.FETCH,
        method: 'GET',
        access_token: readAccessToken(),
        params
      }),
    }),
    useItem: build.mutation({
      query: payload => ({
        url: apiItem.USE,
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
  }),
});


export const {
  useFetchItemQuery,
  useCreateItemMutation,
  useUseItemMutation,
} = itemApi;
