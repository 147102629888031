export enum apiTokenSettings {
  MINT = '/token/mintNft',
  BURN = '/token/burnNft',
  UPDATE = '/token/updateNft',
  MINT_FODDER_SUPPORT = '/token/mintFt',
  BURN_FODDER_SUPPORT = '/token/burnFt',
  UPDATE_FODDER_SUPPORT = '/token/updateFt',
}

export enum selectToken {
  DNA = 'dna',
  REPLICANT = 'replicant',
  FACTOR = 'factor',
  FODDER_ITEM = 'fodderItem',
  SUPPORT_ITEM = 'supportItem',
}

export enum apiAttrTokenSetting {
  NFT_DATA = 'nftData',
  TOKEN_NAME = 'tokenName',
  TOKEN_ID = 'tokenId',
  TOKEN_URI = 'tokenUri',
  TO = 'to',
}

export enum selectOperation {
  MINT = 'mint',
  BURN = 'burn',
  UPDATE = 'update',
}

export enum apiManagementAdmin {
  FETCH = '/users/fetchAdmins',
  CREATE = '/users/createAdmin',
  UPDATE = '/users/updateAdmin',
  DELETE = '/users/deleteAdmin',
}

export enum apiItem {
  CREATE = '/users/addItem',
  USE = '/users/useItem',
  FETCH = '/users/fetchItem',
}

export enum roleKeys {
  ENVIRONMENT = 'environment',
  SUPER_USER = 'admin',
  OPERATOR = 'operator',
  CUSTOMER_SUPPORTER = 'customer_support',
  ALL = 'All',
}

export enum roleLabels {
  ENVIRONMENT = 'Environment',
  SUPER_USER = 'Admin',
  OPERATOR = 'Operator',
  CUSTOMER_SUPPORTER = 'Customer Supporter',
}

export function roles(): any {
  const opts: any = {};
  opts[roleKeys.SUPER_USER] = {
    title: roleLabels.SUPER_USER,
    color: '#f50',
  };
  opts[roleKeys.OPERATOR] = {
    title: roleLabels.OPERATOR,
    color: '#2db7f5',
  };
  opts[roleKeys.CUSTOMER_SUPPORTER] = {
    title: roleLabels.CUSTOMER_SUPPORTER,
    color: '#87d068',
  };

  return opts;
}

export function optionRoles(): any {
  return [
    { value: roleKeys.SUPER_USER, label: roleLabels.SUPER_USER },
    { value: roleKeys.OPERATOR, label: roleLabels.OPERATOR },
    {
      value: roleKeys.CUSTOMER_SUPPORTER,
      label: roleLabels.CUSTOMER_SUPPORTER,
    },
  ];
}

export enum itemCategoryKeys {
  GACHA_TICKET = 1,
  SUPPORT_ITEM = 2,
  FODDER = 3,
  RACE_ITEM = 4,
}

export enum itemCategoryLabels {
  GACHA_TICKET = 'Gacha Ticket',
  SUPPORT_ITEM = 'Support Item',
  FODDER = 'Fodder',
  RACE_ITEM = 'Race Item',
}

export function itemCategory(): any {
  return [
    {
      value: itemCategoryKeys.GACHA_TICKET,
      label: itemCategoryLabels.GACHA_TICKET,
    },
    {
      value: itemCategoryKeys.SUPPORT_ITEM,
      label: itemCategoryLabels.SUPPORT_ITEM,
    },
    {
      value: itemCategoryKeys.FODDER,
      label: itemCategoryLabels.FODDER,
    },
    {
      value: itemCategoryKeys.RACE_ITEM,
      label: itemCategoryLabels.RACE_ITEM,
    },
  ];
}
