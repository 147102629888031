import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '@apps/config/baseQueryWithReauth';
import { readAccessToken } from '@utils/localStorage';

export const presentApi = createApi({
  reducerPath: 'presentApi',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    fetchMdItemData: build.query({
      query: params => ({
        url: '/present/fetchMdItemData',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    addPresent: build.mutation({
      query: payload => ({
        url: '/present/addPresent',
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
  }),
});

export const { useFetchMdItemDataQuery, useAddPresentMutation } = presentApi;
