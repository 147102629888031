import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '@apps/config/baseQueryWithReauth';
// import { readAccessToken } from "@utils/localStorage";
// import { ResponseProps, NPCDataType } from "@globalTypes/globalTypes";

const dummy = [
  {
    key: 1,
    name: 'Training 1',
    skipTurnstile: 1,
    skipDirection: 0,
    simulateTurns: 1,
    fixTheTone: 0,
    earnedItem: 1,
  },
  {
    key: 2,
    name: 'Training 2',
    skipTurnstile: 1,
    skipDirection: 0,
    simulateTurns: 1,
    fixTheTone: 0,
    earnedItem: 1,
  },
  {
    key: 3,
    name: 'Training 3',
    skipTurnstile: 1,
    skipDirection: 0,
    simulateTurns: 1,
    fixTheTone: 0,
    earnedItem: 1,
  },
];

const trainingSkip = {
  status: [0, 1],
};

export const trainingApi = createApi({
  reducerPath: 'trainingApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['trainingApi'],
  endpoints: build => ({
    trainingIndex: build.query({
      queryFn: () => {
        return { data: dummy };
      },
      // query: (page) => ({
      //   url: `/npcs?page=${page}`,
      //   method: "GET",
      //   access_token: readAccessToken(),
      // }),
      // providesTags: ["npcApi"]
    }),
    trainingUpdate: build.mutation({
      queryFn: training => {
        console.log(training);
        return { data: dummy };
      },
      // query: (attr) => ({
      //   url: `/npcs/${attr.id}`,
      //   method: "PUT",
      //   access_token: readAccessToken(),
      // }),
      // providesTags: ["npcApi"],
    }),

    trainingStatus: build.query({
      queryFn: () => {
        return { data: trainingSkip };
      },
      // query: (attr) => ({
      //   url: `/npcs/${attr.id}`,
      //   method: "PUT",
      //   access_token: readAccessToken(),
      // }),
      // providesTags: ["npcApi"],
    }),
  }),
});

export const {
  useTrainingIndexQuery,
  useTrainingUpdateMutation,
  useTrainingStatusQuery,
} = trainingApi;
