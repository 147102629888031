import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '@apps/config/baseQueryWithReauth';
import { readAccessToken } from '@utils/localStorage';

export const kpiApi = createApi({
  reducerPath: 'kpiApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    fetchKpi: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchKpi',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
    fetchInstallKpi: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchInstallKpi',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
    fetchAUKpi: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchAUKpi',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
    fetchPUKpi: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchPUKpi',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
    fetchARPUKpi: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchARPUKpi',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
    fetchARPPUKpi: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchARPPUKpi',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
    fetchFqContinuityRateKpi: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchFqContinuityRateKpi',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
    fetchFqTotalSalesKpi: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchFqTotalSalesKpi',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
    fetchSalesNcoinKpi: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchSalesNcoinKpi',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
    fetchSalesNBGDcoinKpi: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchSalesNBGDcoinKpi',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
    fetchMdLootBoxData: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchMdLootBoxData',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
    fetchSalesGachaKpi: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchSalesGachaKpi',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
    fetchUserTrendsCoinKpi: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchUserTrendsCoinKpi',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
    fetchUserTrendsItemKpi: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchUserTrendsItemKpi',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
    fetchUserTrendsNBGDconsumptionKpi: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchUserTrendsNBGDconsumptionKpi',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
    fetchUserTrendsTrainingCompleteKpi: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchUserTrendsTrainingCompleteKpi',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
    fetchUserTrendsSearchKpi: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchUserTrendsSearch',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
  }),
});

export const {
  useFetchKpiQuery,
  useFetchInstallKpiQuery,
  useFetchAUKpiQuery,
  useFetchPUKpiQuery,
  useFetchARPUKpiQuery,
  useFetchARPPUKpiQuery,
  useFetchFqContinuityRateKpiQuery,
  useFetchFqTotalSalesKpiQuery,
  useFetchSalesNcoinKpiQuery,
  useFetchSalesNBGDcoinKpiQuery,
  useFetchMdLootBoxDataQuery,
  useFetchSalesGachaKpiQuery,
  useFetchUserTrendsCoinKpiQuery,
  useFetchUserTrendsItemKpiQuery,
  useFetchUserTrendsNBGDconsumptionKpiQuery,
  useFetchUserTrendsTrainingCompleteKpiQuery,
  useFetchUserTrendsSearchKpiQuery,
} = kpiApi;
