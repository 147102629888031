import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '@apps/config/baseQueryWithReauth';
import { readAccessToken } from '@utils/localStorage';

export const noticeApi = createApi({
  reducerPath: 'noticeApi',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    updateNotice: build.mutation({
      query: payload => ({
        url: '/notice/updateNotice',
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
    fetchNotice: build.query({
      query: params => ({
        url: '/notice/fetchNotice',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    upsertNotice: build.mutation({
      query: payload => ({
        url: '/notice/upsertNotice',
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
  }),
});

export const {
  useUpdateNoticeMutation,
  useFetchNoticeQuery,
  useUpsertNoticeMutation,
} = noticeApi;
