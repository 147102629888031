import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '@apps/config/baseQueryWithReauth';
import { readAccessToken } from '@utils/localStorage';

export const maintenanceApi = createApi({
  reducerPath: 'maintenanceApi',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    create: build.mutation({
      query: payload => ({
        url: '/maintenance/setMaintenanceSchedule',
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
    fetchMaintenances: build.query({
      query: () => ({
        url: '/maintenance/fetchMaintenances',
        method: 'GET',
        access_token: readAccessToken(),
      }),
    }),
    deleteMaintenance: build.mutation({
      query: payload => ({
        url: '/maintenance/deleteMaintenance',
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
    updateMaintenance: build.mutation({
      query: payload => ({
        url: '/maintenance/updateMaintenance',
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    })
  }),
});

export const {
  useCreateMutation,
  useFetchMaintenancesQuery,
  useDeleteMaintenanceMutation,
  useUpdateMaintenanceMutation
} = maintenanceApi;
