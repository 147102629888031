import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '@apps/config/baseQueryWithReauth';
import { readAccessToken } from '@utils/localStorage';
import { apiTokenSettings } from '../../enums/globalEnums';

export const tokenSettingApi = createApi({
  reducerPath: 'tokenSettingApi',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    mintToken: build.mutation({
      query: body => ({
        url: apiTokenSettings.MINT,
        method: 'POST',
        access_token: readAccessToken(),
        body: body,
      }),
    }),
    burnToken: build.mutation({
      query: body => ({
        url: apiTokenSettings.BURN,
        method: 'POST',
        access_token: readAccessToken(),
        body: body,
      }),
    }),
    updateToken: build.mutation({
      query: body => ({
        url: apiTokenSettings.UPDATE,
        method: 'POST',
        access_token: readAccessToken(),
        body: body,
      }),
    }),
  }),
});

export const {
  useMintTokenMutation,
  useBurnTokenMutation,
  useUpdateTokenMutation,
} = tokenSettingApi;
