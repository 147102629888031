import { logout } from '@apps/slices/authSlice';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { notification } from 'antd';
import { Mutex } from 'async-mutex';
import axios from 'axios';
import { readAccessToken } from '@utils/localStorage';
import { ErrorProps } from '@globalTypes/globalTypes';
import { decode } from '@msgpack/msgpack';

const configs: any = {
  local: {
    name: process.env.REACT_APP_LOCAL_NAME,
    apiUrl: process.env.REACT_APP_LOCAL_API_URL,
  },
  dev: {
    name: process.env.REACT_APP_DEV_NAME,
    apiUrl: process.env.REACT_APP_DEV_API_URL,
  },
  stg: {
    name: process.env.REACT_APP_STG_NAME,
    apiUrl: process.env.REACT_APP_STG_API_URL,
  },
  release: {
    name: process.env.REACT_APP_RELEASE_NAME,
    apiUrl: process.env.REACT_APP_RELEASE_API_URL,
  },
};
// also get current env type from .env file through REACT_APP_ENV
let current_env = localStorage.getItem('env');
if (current_env == null)
  current_env = process.env.REACT_APP_ENV!;

window.CURRENT_ENV = configs[current_env || "dev"];
const PATH_API = window.CURRENT_ENV.apiUrl;

// create a new mutex
const mutex = new Mutex();

const instance = axios.create({
  baseURL: PATH_API,
  responseType: 'arraybuffer',
});

instance.interceptors.request.use(
  (config: any) => {
    config.headers.Authorization = `Bearer ${readAccessToken()}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

const callApi = async (args: any) => {
  if (args.method.toLowerCase() === 'get') {
    return await instance.get(`${PATH_API}${args.url}`, {
      params: args.params,
    });
  }

  return await instance.post(`${PATH_API}${args.url}`, args.body);
};

const baseQuery = async (args: any) => {
  return await callApi(args);
};

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api) => {
  await mutex.waitForUnlock();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = await baseQuery(args);
  result.data = decode(result.data);
  const error = result.data.error;

  if (error && error.code) {
    switch (error.code) {
      case 400:
        notification.error({
          message: 'Bad request',
        });
        break;
      case 401:
        api.dispatch(logout());
        notification.error({
          message: error.message,
        });
        // if (!mutex.isLocked()) {
        //   const release = await mutex.acquire();
        //   try {
        //     // try to get a new token
        //     if (access_token && refresh_token) {
        //       // eslint-disable-next-line @typescript-eslint/no-explicit-any
        //       const refreshResult: any = await baseQueryNotAuth(
        //         {
        //           url: '/auth/refresh-token',
        //           method: 'POST',
        //           body: { token: refresh_token },
        //         }
        //       );

        //       if (refreshResult.data) {
        //         // reset access_token in localStorage
        //         saveAccessToken(refreshResult.data?.access_token);
        //         saveRefreshToken(refreshResult.data?.refresh_token);

        //         // reset access_token in redux
        //         await api.dispatch(setCredentials(refreshResult.data));

        //         // retry the initial queryresult = await baseQuery(args, api, extraOptions)
        //         result = await baseQuery(args);
        //       } else {
        //         api.dispatch(logout());
        //       }
        //     } else {
        //       api.dispatch(logout());
        //     }
        //   } finally {
        //     // release must be called once the mutex should be released again.
        //     release();
        //   }
        // } else {
        //   await mutex.waitForUnlock();
        //   result = await baseQuery(args);
        // }
        break;
      case 403:
        notification.error({
          message: error.message,
        });
        api.dispatch(logout());
        break;
      case 422:
        const getError = Object.values(
          (result.error?.data as ErrorProps)?.errors,
        )[0];

        notification.error({
          message: getError[0],
        });
        break;
      case 500:
        notification.error({
          message: 'Error',
        });
        break;
      case 501:
      case 502:
      case 503:
        notification.error({
          message: 'Internal Server Error',
        });
        break;
    }
  }
  return result;
};
export default baseQueryWithReauth;
