import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '@apps/config/baseQueryWithReauth';
import { readAccessToken } from '@utils/localStorage';

export const managementEndUserApi = createApi({
  reducerPath: 'managementEndUserApi',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    fetchUserInfo: build.query({
      query: params => ({
        url: '/enduser/fetchUserInfo',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    fetchMdItemData: build.query({
      query: params => ({
        url: '/enduser/fetchMdItemData',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    fetchUserItems: build.query({
      query: params => ({
        url: '/enduser/fetchUserItems',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    createNewItem: build.mutation({
      query: payload => ({
        url: '/enduser/addUserItem',
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
    updateItemAmount: build.mutation({
      query: payload => ({
        url: '/enduser/updateUserItem',
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
    fetchUserReplicants: build.query({
      query: params => ({
        url: '/enduser/fetchUserReplicants',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    fetchUserInstranceReplicants: build.query({
      query: params => ({
        url: '/enduser/fetchUserInstantReplicants',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    createInstantReplicant: build.mutation({
      query: payload => ({
        url: '/enduser/addInstantReplicant',
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
    updateInstantReplicant: build.mutation({
      query: payload => ({
        url: '/enduser/updateInstantReplicant',
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
    fetchAllMdDnaData: build.query({
      query: params => ({
        url: '/enduser/fetchAllMdDna',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    fetchMdDnaData: build.query({
      query: params => ({
        url: '/enduser/fetchMdDnaData',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    fetchAllMdDnaGachaContentId: build.query({
      query: params => ({
        url: '/enduser/fetchAllMdDnaGachaContentId',
        method: 'GET',
        access_token: readAccessToken(),
      }),
    }),
    createInstantDna: build.mutation({
      query: payload => ({
        url: '/enduser/addInstantDna',
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
    fetchUserDnas: build.query({
      query: params => ({
        url: '/enduser/fetchUserDnas',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    fetchUserInstantDnas: build.query({
      query: params => ({
        url: '/enduser/fetchUserInstantDnas',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    fetchMdGeneData: build.query({
      query: params => ({
        url: '/enduser/fetchMdGeneData',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    fetchAllMdGeneGachaContentId: build.query({
      query: params => ({
        url: '/enduser/fetchAllMdGene',
        method: 'GET',
        access_token: readAccessToken(),
      }),
    }),
    createInstantGene: build.mutation({
      query: payload => ({
        url: '/enduser/addInstantGene',
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
    updateInstantDna: build.mutation({
      query: payload => ({
        url: '/enduser/updateInstantDna',
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
    fetchUserGenes: build.query({
      query: params => ({
        url: '/enduser/fetchUserGenes',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    fetchUserInstantGenes: build.query({
      query: params => ({
        url: '/enduser/fetchUserInstantGenes',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    updateInstantGene: build.mutation({
      query: payload => ({
        url: '/enduser/updateInstantGene',
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
    fetchPresent: build.query({
      query: params => ({
        url: '/enduser/fetchPresentBox',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    updateUserPresentBox: build.mutation({
      query: payload => ({
        url: '/enduser/updateUserPresentBox',
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
    fetchUserCoins: build.query({
      query: params => ({
        url: '/enduser/fetchUserCoins',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    addUserCoin: build.mutation({
      query: payload => ({
        url: '/enduser/addUserCoin',
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
    updateUserCoin: build.mutation({
      query: payload => ({
        url: '/enduser/updateUserCoins',
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
    fetchUserCoinHistory: build.query({
      query: params => ({
        url: '/enduser/fetchUserCoinHistory',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    fetchPvpRaceHistory: build.query({
      query: params => ({
        url: '/enduser/fetchPvpRaceHistory',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    fetchTrainingHistory: build.query({
      query: params => ({
        url: '/enduser/fetchTrainingHistory',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
  }),
});

export const {
  useFetchUserInfoQuery,
  useFetchMdItemDataQuery,
  useCreateNewItemMutation,
  useFetchUserItemsQuery,
  useUpdateItemAmountMutation,
  useFetchUserReplicantsQuery,
  useFetchUserInstranceReplicantsQuery,
  useCreateInstantReplicantMutation,
  useUpdateInstantReplicantMutation,
  useFetchAllMdDnaDataQuery,
  useFetchMdDnaDataQuery,
  useFetchAllMdDnaGachaContentIdQuery,
  useCreateInstantDnaMutation,
  useUpdateInstantDnaMutation,
  useFetchUserDnasQuery,
  useFetchUserInstantDnasQuery,
  useCreateInstantGeneMutation,
  useFetchUserGenesQuery,
  useFetchUserInstantGenesQuery,
  useUpdateInstantGeneMutation,
  useFetchAllMdGeneGachaContentIdQuery,
  useFetchMdGeneDataQuery,
  useFetchPresentQuery,
  useUpdateUserPresentBoxMutation,
  useFetchUserCoinsQuery,
  useAddUserCoinMutation,
  useUpdateUserCoinMutation,
  useFetchUserCoinHistoryQuery,
  useFetchPvpRaceHistoryQuery,
  useFetchTrainingHistoryQuery,
} = managementEndUserApi;
