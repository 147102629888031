import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '@apps/config/baseQueryWithReauth';
import { readAccessToken } from '@utils/localStorage';
import { apiManagementAdmin } from '../../enums/globalEnums';

export const managementAdminApi = createApi({
  reducerPath: 'managementAdminApi',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    createAdmin: build.mutation({
      query: payload => ({
        url: apiManagementAdmin.CREATE,
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
    fetchAdmin: build.query({
      query: () => ({
        url: apiManagementAdmin.FETCH,
        method: 'GET',
        access_token: readAccessToken(),
      }),
    }),
    updateAdmin: build.mutation({
      query: payload => ({
        url: apiManagementAdmin.UPDATE,
        method: 'POST',
        access_token: readAccessToken(),
        body: payload,
      }),
    }),
    deleteAdmin: build.mutation({
      query: id => ({
        url: apiManagementAdmin.DELETE,
        method: 'POST',
        access_token: readAccessToken(),
        body: { id },
      }),
    }),
  }),
});

export const {
  useCreateAdminMutation,
  useFetchAdminQuery,
  useUpdateAdminMutation,
  useDeleteAdminMutation,
} = managementAdminApi;
