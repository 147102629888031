import { configureStore } from '@reduxjs/toolkit';
import { authServiceApi } from './services/authService';
import { npcApi } from './services/npcService';
import { itemApi } from './services/itemService';
import { racehorseApi } from './services/raceHorseService';
import { trainingApi } from './services/trainingService';
import { tokenSettingApi } from './services/tokenSettingService';
import authSliceReducer from './slices/authSlice';
import { managementAdminApi } from './services/managementAdminService';
import { maintenanceApi } from './services/maintenanceService';
import { kpiApi } from './services/kpiService';
import { managementEndUserApi } from './services/managementEndUser';
import { changeTimeApi } from './services/changeTime';
import { presentApi } from './services/presentService';
import { noticeApi } from './services/noticeService';

export const store = configureStore({
  reducer: {
    [authServiceApi.reducerPath]: authServiceApi.reducer,
    auth: authSliceReducer,
    [npcApi.reducerPath]: npcApi.reducer,
    [itemApi.reducerPath]: itemApi.reducer,
    [racehorseApi.reducerPath]: racehorseApi.reducer,
    [trainingApi.reducerPath]: trainingApi.reducer,
    [tokenSettingApi.reducerPath]: tokenSettingApi.reducer,
    [managementAdminApi.reducerPath]: managementAdminApi.reducer,
    [maintenanceApi.reducerPath]: maintenanceApi.reducer,
    [kpiApi.reducerPath]: kpiApi.reducer,
    [managementEndUserApi.reducerPath]: managementEndUserApi.reducer,
    [changeTimeApi.reducerPath]: changeTimeApi.reducer,
    [presentApi.reducerPath]: presentApi.reducer,
    [noticeApi.reducerPath]: noticeApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(authServiceApi.middleware)
      .concat(npcApi.middleware)
      .concat(itemApi.middleware)
      .concat(racehorseApi.middleware)
      .concat(trainingApi.middleware)
      .concat(tokenSettingApi.middleware)
      .concat(managementAdminApi.middleware)
      .concat(maintenanceApi.middleware)
      .concat(kpiApi.middleware)
      .concat(managementEndUserApi.middleware)
      .concat(changeTimeApi.middleware)
      .concat(presentApi.middleware)
      .concat(noticeApi.middleware),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
