import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '@apps/config/baseQueryWithReauth';
// import { readAccessToken } from "@utils/localStorage";
// import { ResponseProps, NPCDataType } from "@globalTypes/globalTypes";

const dummy = [
  {
    key: 1,
    name: 'Racehorse 1',
    appearance: {
      personality: 1,
      furColor: 1,
      head: 1,
      leg: 1,
      back: 1,
      tattoo: 1,
    },
    status: 0,
  },
  {
    key: 2,
    name: 'Racehorse 2',
    appearance: {
      personality: 2,
      furColor: 2,
      head: 2,
      leg: 2,
      back: 2,
      tattoo: 2,
    },
    status: 0,
  },
  {
    key: 3,
    name: 'Racehorse 3',
    appearance: {
      personality: 3,
      furColor: 3,
      head: 3,
      leg: 3,
      back: 3,
      tattoo: 3,
    },
    status: 1,
  },
];

const ids = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const racehorseAppearance = {
  personality: ids,
  furColor: ids,
  head: ids,
  leg: ids,
  back: ids,
  tattoo: ids,
};

const racehorseStatus = {
  status: ['Available', 'Racing', 'Listing', 'Renting'],
};

export const racehorseApi = createApi({
  reducerPath: 'racehorseApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['racehorseApi'],
  endpoints: build => ({
    racehorseIndex: build.query({
      queryFn: () => {
        return { data: dummy };
      },
      // query: (page) => ({
      //   url: `/npcs?page=${page}`,
      //   method: "GET",
      //   access_token: readAccessToken(),
      // }),
      // providesTags: ["npcApi"]
    }),
    racehorseUpdate: build.mutation({
      queryFn: racehorse => {
        console.log(racehorse);
        return { data: dummy };
      },
      // query: (attr) => ({
      //   url: `/npcs/${attr.id}`,
      //   method: "PUT",
      //   access_token: readAccessToken(),
      // }),
      // providesTags: ["npcApi"],
    }),
    racehorseAppearance: build.query({
      queryFn: () => {
        return { data: racehorseAppearance };
      },
      // query: (attr) => ({
      //   url: `/npcs/${attr.id}`,
      //   method: "PUT",
      //   access_token: readAccessToken(),
      // }),
      // providesTags: ["npcApi"],
    }),
    racehorseStatus: build.query({
      queryFn: () => {
        return { data: racehorseStatus };
      },
      // query: (attr) => ({
      //   url: `/npcs/${attr.id}`,
      //   method: "PUT",
      //   access_token: readAccessToken(),
      // }),
      // providesTags: ["npcApi"],
    }),
  }),
});

export const {
  useRacehorseIndexQuery,
  useRacehorseUpdateMutation,
  useRacehorseAppearanceQuery,
  useRacehorseStatusQuery,
} = racehorseApi;
