import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '@apps/config/baseQueryWithReauth';
import { readAccessToken } from '@utils/localStorage';

export const kpiApi = createApi({
  reducerPath: 'kpiApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    fetchKpi: builder.query({
      query: params => {
        return {
          url: '/kpi/fetchKpi',
          method: 'GET',
          access_token: readAccessToken(),
          params,
        };
      },
    }),
  }),
});

export const { useFetchKpiQuery } = kpiApi;
