import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '@apps/config/baseQueryWithReauth';
import { readAccessToken } from '@utils/localStorage';

export const changeTimeApi = createApi({
  reducerPath: 'changeTimeApi',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    fetchTimeInfo: build.query({
      query: params => ({
        url: '/changeTime/fetchTime',
        method: 'GET',
        access_token: readAccessToken(),
        params,
      }),
    }),
    updateTime: build.mutation({
      query: payload => ({
        url: '/changeTime/updateTime',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const { useFetchTimeInfoQuery, useUpdateTimeMutation } = changeTimeApi;
