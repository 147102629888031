import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu, Popconfirm, Button } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import {readEnv, readId, readRoles} from '@utils/localStorage';
import { useAppDispatch } from '@apps/hooks';
import { logout } from '@apps/slices/authSlice';
import { roleKeys } from 'enums/globalEnums';

const { Sider, Content } = Layout;

interface AppLayoutInterface {
  children: JSX.Element;
  showMenu: boolean;
}
const menuItems = [
  {
    key: '/environment',
    label: <Link to="/environment">Environment</Link>,
    items: [],
    role: roleKeys.ENVIRONMENT,
  },
  {
    key: '/item',
    label: <Link to="/item">Item Editor</Link>,
    items: [],
    role: roleKeys.SUPER_USER,
  },
  {
    key: '/token-setting',
    label: <Link to="/token-setting">Token Settings</Link>,
    items: [],
    role: roleKeys.OPERATOR,
  },
  {
    key: '/maintenance',
    label: <Link to="/maintenance">Maintenance</Link>,
    items: [],
    role: roleKeys.SUPER_USER,
  },
  {
    key: '/kpi',
    label: <Link to="/kpi">KPI</Link>,
    items: [],
    role: roleKeys.SUPER_USER,
  },
  {
    key: '/management-end-user',
    label: <Link to="/management-end-user">Management End User</Link>,
    items: [],
    role: roleKeys.CUSTOMER_SUPPORTER,
  },
  {
    key: '/management-admin',
    label: <Link to="/management-admin">Management Admin Users</Link>,
    items: [],
    role: roleKeys.SUPER_USER,
  },
  {
    key: '/present',
    label: <Link to="/present">Present</Link>,
    items: [],
    role: roleKeys.ALL,
  },
  {
    key: '/change-time',
    label: <Link to="/change-time">Change Time</Link>,
    items: [],
    role: roleKeys.ALL,
  },
];

const getEnvColor = () => {
  let env = readEnv();
  switch (env) {
    case 'local':
    case 'dev':
    case 'stg':
      return '#2f4791';
    case 'release':
      return '#912F47';
    default:
      return 'green';
  }
};

// const pathname = menuItems[0].key;
const LeftMenu = () => {
  const { pathname } = useLocation();
  const initMenu = getMenuItems();
  return (
    <div>
      <div className="logo" style={{backgroundColor: getEnvColor()}}>
        <Link to="/environment">
          <img
            src="https://news.vmogroup.com/wp-content/uploads/2023/04/VMO_Logo_Positive.png"
            alt="logo"
          />
        </Link>
        <div className="user_name">
          <Link to="/user-profile">
            <UserOutlined /> {readEnv()}: {readId()}
          </Link>
        </div>
      </div>
      <Menu defaultSelectedKeys={[pathname]} mode="inline" items={initMenu} style={{backgroundColor: getEnvColor()}}/>
    </div>
  );
};

const getMenuItems = () => {
  let userRoles: any = readRoles();
  userRoles = JSON.parse(userRoles);
  let items: any;
  if (userRoles.includes(roleKeys.SUPER_USER)) {
    items = menuItems;
  } else {
    items = menuItems.filter(
      item => userRoles.includes(item.role) || item.role === roleKeys.ALL,
    );
    items.unshift(menuItems[0]);
  }

  if (window.CURRENT_ENV?.name === process.env.REACT_APP_RELEASE_NAME) {
    return items.filter((item: any) => item.role !== roleKeys.OPERATOR);
  }

  return items;
};

export default function AppLayout({ children, showMenu }: AppLayoutInterface) {
  const appDispatch = useAppDispatch();
  const handleLogout = () => {
    appDispatch(logout());
  };

  return (
    <Layout id="app-layout">
      {showMenu && (
        <div style={{backgroundColor: getEnvColor()}}>
          <Sider trigger={null} width={320}>
            <LeftMenu />
            <div className="logout" style={{backgroundColor: getEnvColor()}}>
              <Popconfirm
                placement="rightTop"
                title="Do you want to sign out?"
                onConfirm={handleLogout}
                okText="Yes"
                cancelText="No"
              >
                <Button size="large" ghost>
                  <LogoutOutlined />
                  Logout
                </Button>
              </Popconfirm>
            </div>
          </Sider>
        </div>
      )}
      <Layout className="site-layout">
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
          }}
        >
          <article className="app-content">{children}</article>
        </Content>
      </Layout>
    </Layout>
  );
}