/* eslint-disable @typescript-eslint/no-explicit-any */
export function getObjectStringName(value: any) {
  return Object.prototype.toString.call(value);
}

function setLocalStorage(key: string, value: any) {
  const name = getObjectStringName(value);

  if (name === '[object String]') localStorage.setItem(key, value);
  else localStorage.setItem(key, JSON.stringify(value));
}

export function getStringLocalStorage(key: string) {
  return localStorage.getItem(key);
}

export function getJsonLocalStorage(key: string) {
  return JSON.parse(localStorage.getItem(key) || '{}');
}

export function getArrJsonLocalStorage(key: string) {
  return JSON.parse(localStorage.getItem(key) || '[]');
}

// ===== ACCESS_TOKEN =====
const ACCESS_TOKEN_KEY = 'accessToken';
export function saveAccessToken(accessToken: string) {
  setLocalStorage(ACCESS_TOKEN_KEY, accessToken);
}
export function readAccessToken() {
  return getStringLocalStorage(ACCESS_TOKEN_KEY);
}

// ===== ACCESS_TOKEN =====
const REFRESH_TOKEN_KEY = 'refreshToken';
export function saveRefreshToken(refreshToken: string) {
  setLocalStorage(REFRESH_TOKEN_KEY, refreshToken);
}
export function readRefreshToken() {
  return getStringLocalStorage(REFRESH_TOKEN_KEY);
}

// current menu key
const MENU_OPEN_KEY = 'menuOpenKeys';
export function saveMenuOpenKeys(openKeys: any) {
  setLocalStorage(MENU_OPEN_KEY, openKeys);
}
export function readMenuOpenKeys() {
  return getArrJsonLocalStorage(MENU_OPEN_KEY);
}

// ===== ROLES =====
const ROLES = 'roles';
export function saveRoles(roles: any) {
  setLocalStorage(ROLES, JSON.stringify(roles));
}
export function readRoles() {
  return getStringLocalStorage(ROLES);
}

// ===== ENV =====
//const ENV_KEY = 'env';
export function readEnv() {
  return process.env.REACT_APP_ENV;
  //return getStringLocalStorage(ENV_KEY);
}

// ===== ID =====
const ID_KEY = 'id';
export function saveId(id: string) {
  setLocalStorage(ID_KEY, id);
}
export function readId() {
  return getStringLocalStorage(ID_KEY);
}

// ===== NAME =====
const NAME_KEY = 'name';
export function saveName(name: string) {
  setLocalStorage(NAME_KEY, name);
}
export function readName() {
  return getStringLocalStorage(NAME_KEY);
}
