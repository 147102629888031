import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu, Popconfirm, Button } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { readEnv, readId, readRoles } from '@utils/localStorage';
import { useAppDispatch } from '@apps/hooks';
import { logout } from '@apps/slices/authSlice';
import { roleKeys } from 'enums/globalEnums';

const { Sider, Content } = Layout;

interface AppLayoutInterface {
  children: JSX.Element;
  showMenu: boolean;
}
const menuItems = [
  {
    key: '/environment',
    label: <Link to="/environment">Environment</Link>,
    items: [],
    role: roleKeys.ENVIRONMENT,
  },
  {
    key: '/item',
    label: <Link to="/item">Item Editor</Link>,
    items: [],
    role: roleKeys.SUPER_USER,
  },
  {
    key: '/token-setting',
    label: <Link to="/token-setting">Token Settings </Link>,
    items: [],
    role: roleKeys.OPERATOR,
  },
  {
    key: '/maintenance',
    label: <Link to="/maintenance">Maintenance</Link>,
    items: [],
    role: roleKeys.SUPER_USER,
  },
  {
    key: '/kpi',
    label: <div style={{ fontSize: '16px' }}>KPI</div>,
    items: [],
    role: roleKeys.OPERATOR,
    children: [
      {
        key: '/kpi/install',
        label: <Link to="/kpi/install">install</Link>,
        items: [],
        role: roleKeys.OPERATOR,
      },
      {
        key: '/kpi/AU',
        label: <Link to="/kpi/AU">AU</Link>,
        items: [],
        role: roleKeys.OPERATOR,
      },
      {
        key: '/kpi/PU',
        label: <Link to="/kpi/PU">PU</Link>,
        items: [],
        role: roleKeys.OPERATOR,
      },
      {
        key: '/kpi/ARPU',
        label: <Link to="/kpi/ARPU">ARPU</Link>,
        items: [],
        role: roleKeys.OPERATOR,
      },
      {
        key: '/kpi/ARPPU',
        label: <Link to="/kpi/ARPPU">ARPPU</Link>,
        items: [],
        role: roleKeys.OPERATOR,
      },
      {
        key: '/kpi/FqContinuityRate',
        label: <Link to="/kpi/FqContinuityRate">継続率</Link>,
        items: [],
        role: roleKeys.OPERATOR,
      },
      {
        key: '/kpi/FqTotalSales',
        label: <Link to="/kpi/FqTotalSales">課金継続率</Link>,
        items: [],
        role: roleKeys.OPERATOR,
      },
      {
        key: '/kpi/sales/Ncoin',
        label: (
          <Link to="/kpi/sales/Ncoin">
            <div style={{ fontSize: '16px' }}>SALES</div>
          </Link>
        ),
        items: [],
        role: roleKeys.OPERATOR,
        children: [
          {
            key: '/kpi/sales/Ncoin',
            label: <Link to="/kpi/sales/Ncoin">Nコイン</Link>,
            items: [],
            role: roleKeys.OPERATOR,
          },
          {
            key: '/kpi/sales/NBGDcoin',
            label: <Link to="/kpi/sales/NBGDcoin">NBGDコイン</Link>,
            items: [],
            role: roleKeys.OPERATOR,
          },
          {
            key: '/kpi/sales/GACHA',
            label: <Link to="/kpi/sales/GACHA">GACHA</Link>,
            items: [],
            role: roleKeys.OPERATOR,
          },
        ],
      },
      {
        key: '/kpi/userTrends/coin',
        label: (
          <Link to="/kpi/userTrends/coin">
            <div style={{ fontSize: '16px' }}>ユーザー動向</div>
          </Link>
        ),
        items: [],
        role: roleKeys.OPERATOR,
        children: [
          {
            key: '/kpi/userTrends/coin',
            label: <Link to="/kpi/userTrends/coin">ユーザー所持</Link>,
            items: [],
            role: roleKeys.OPERATOR,
          },
          {
            key: '/kpi/userTrends/item',
            label: <Link to="/kpi/userTrends/item">アイテム所持</Link>,
            items: [],
            role: roleKeys.OPERATOR,
          },
          {
            key: '/kpi/userTrends/NBGDconsumption',
            label: <Link to="/kpi/userTrends/NBGDconsumption">NBGD消費</Link>,
            items: [],
            role: roleKeys.SUPER_USER,
          },
          {
            key: '/kpi/userTrends/TrainingComplete',
            label: <Link to="/kpi/userTrends/TrainingComplete">育成完了</Link>,
            items: [],
            role: roleKeys.SUPER_USER,
          },
          {
            key: '/kpi/userTrends/search',
            label: <Link to="/kpi/userTrends/search">ユーザー検索</Link>,
            items: [],
            role: roleKeys.SUPER_USER,
          },
        ],
      },
    ],
  },
  {
    key: '/management-end-user',
    label: <Link to="/management-end-user">Management End User</Link>,
    items: [],
    role: roleKeys.CUSTOMER_SUPPORTER,
  },
  {
    key: '/management-admin',
    label: <Link to="/management-admin">Management Admin Users</Link>,
    items: [],
    role: roleKeys.SUPER_USER,
  },
  {
    key: '/notice',
    label: <Link to="/notice">Notice</Link>,
    items: [],
    role: roleKeys.SUPER_USER,
  },
  {
    key: '/present',
    label: <Link to="/present">Present</Link>,
    items: [],
    role: roleKeys.ALL,
  },
  {
    key: '/change-time',
    label: <Link to="/change-time">Change Time</Link>,
    items: [],
    role: roleKeys.ALL,
  },
];

const getEnvColor = () => {
  let env = readEnv();
  switch (env) {
    case 'local':
    case 'dev':
    case 'stg':
      return '#2f4791';
    case 'release':
      return '#912F47';
    default:
      return 'green';
  }
};

// const pathname = menuItems[0].key;
const LeftMenu = () => {
  const { pathname } = useLocation();
  const initMenu = getMenuItems();
  return (
    <div>
      <div className="logo" style={{ backgroundColor: getEnvColor() }}>
        <Link to="/environment">NEOBRED</Link>
        <div className="user_name">
          <Link to="/user-profile">
            <UserOutlined /> {readEnv()}: {readId()}
          </Link>
        </div>
      </div>
      <Menu
        defaultSelectedKeys={[pathname]}
        mode="inline"
        items={initMenu}
        style={{ backgroundColor: getEnvColor() }}
      />
    </div>
  );
};

const getMenuItems = () => {
  let userRoles: any = readRoles();
  userRoles = JSON.parse(userRoles);
  let items: any;
  if (userRoles.includes(roleKeys.SUPER_USER)) {
    items = menuItems;
  } else {
    items = menuItems.filter(
      item => userRoles.includes(item.role) || item.role === roleKeys.ALL,
    );
    items.unshift(menuItems[0]);
  }

  if (window.CURRENT_ENV?.name === process.env.REACT_APP_RELEASE_NAME) {
    return items.filter((item: any) => item.role !== roleKeys.OPERATOR);
  }

  return items;
};

export default function AppLayout({ children, showMenu }: AppLayoutInterface) {
  const appDispatch = useAppDispatch();
  const handleLogout = () => {
    appDispatch(logout());
  };

  return (
    <Layout id="app-layout">
      {showMenu && (
        <div style={{ backgroundColor: getEnvColor() }}>
          <Sider trigger={null} width={320}>
            <LeftMenu />
            <div className="logout" style={{ backgroundColor: getEnvColor() }}>
              <Popconfirm
                placement="rightTop"
                title="Do you want to sign out?"
                onConfirm={handleLogout}
                okText="Yes"
                cancelText="No"
              >
                <Button size="large" ghost>
                  <LogoutOutlined />
                  Logout
                </Button>
              </Popconfirm>
            </div>
          </Sider>
        </div>
      )}
      <Layout className="site-layout">
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
          }}
        >
          <article className="app-content">{children}</article>
        </Content>
      </Layout>
    </Layout>
  );
}
