import { lazy } from 'react';

export const routeConfig = [
  // {
  //   id: "RegisterPage",
  //   path: "/register",
  //   component: lazy(
  //     () => import(/* webpackPrefetch: true */ "@pages/register/RegisterPage"),
  //   ),
  //   isProtected: false,
  // },
  {
    id: 'LoginPage',
    path: '/login',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/login/LoginPage'),
    ),
    isProtected: false,
  },
  // {
  //   id: "InGameLace",
  //   name: "InGame Lace",
  //   path: "/game/ingame/lace",
  //   component: lazy(
  //     () =>
  //       import(
  //         /* webpackPrefetch: true */ "@pages/game-panel/game/ingame/Lace"
  //       ),
  //   ),
  //   isProtected: false,
  // },
  // {
  //   id: "InGameTraining",
  //   name: "InGame Training",
  //   path: "/game/ingame/training",
  //   component: lazy(
  //     () =>
  //       import(
  //         /* webpackPrefetch: true */ "@pages/game-panel/game/ingame/Training"
  //       ),
  //   ),
  //   isProtected: false,
  // },
  // {
  //   id: "OutgameGeneral",
  //   name: "Outgame General",
  //   path: "/game/outgame/general",
  //   component: lazy(
  //     () =>
  //       import(
  //         /* webpackPrefetch: true */ "@pages/game-panel/game/outgame/General"
  //       ),
  //   ),
  //   isProtected: false,
  // },
  {
    id: 'Environment',
    name: 'Environment',
    path: '/environment',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/environment'),
    ),
    isProtected: true,
  },
  {
    id: 'ItemEditor',
    name: 'ItemEditor',
    path: '/item',
    component: lazy(() => import(/* webpackPrefetch: true */ '@pages/item')),
    isProtected: true,
  },
  {
    id: 'TokenSetting',
    name: 'Token Setting',
    path: '/token-setting',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/token-setting'),
    ),
    isProtected: true,
  },
  {
    id: 'Maintenance',
    name: 'Maintenance',
    path: '/maintenance',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/maintenance'),
    ),
    isProtected: true,
  },
  {
    id: 'KPI',
    name: 'KPI',
    path: '/kpi',
    component: lazy(() => import(/* webpackPrefetch: true */ '@pages/kpi')),
    isProtected: true,
  },
  {
    id: 'EndUserManagement',
    name: 'End User Management',
    path: '/management-end-user',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/management-end-user'),
    ),
    isProtected: true,
  },
  {
    id: 'ManagementAdmin',
    name: 'Management Admin',
    path: '/management-admin',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/management-admin'),
    ),
    isProtected: true,
  },
  {
    id: 'Present',
    name: 'Present',
    path: '/present',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/present/present'),
    ),
    isProtected: true,
  },
  {
    id: 'ChangeTime',
    name: 'Change Time',
    path: '/change-time',
    component: lazy(
      () => import(/* webpackPrefetch: true */ '@pages/change-time'),
    ),
    isProtected: true,
  },
];
